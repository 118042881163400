<template>
  <div class="columns is-centered is-marginless" style="height: 100%">
    <div
      class="column is-6"
      style="height: 100%"
    >
      <div class="column is-narrow">
        <h1 class="is-size-4 has-text-centered has-text-weight-medium">
          We are here to help you!
        </h1>
      </div>
      <div v-if="!intercom" class="column is-narrow">
        <form @submit.prevent="" class="mb-5">
          <b-field
            custom-class=""
            :type="error.title ? 'is-danger' : ''"
            :message="error.title"
          >
            <b-input
              placeholder="Title"
              custom-class="custum-input"
              v-model="dataHelp.title">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.subject ? 'is-danger' : ''"
            :message="error.subject"
          >
            <b-input
              placeholder="Subject"
              custom-class="custum-input"
              v-model="dataHelp.subject">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.email ? 'is-danger' : ''"
            :message="error.email"
          >
            <b-input
              placeholder="Email"
              custom-class="custum-input"
              type="email"
              v-model="dataHelp.email">
            </b-input>
          </b-field>
          <b-field
            custom-class=""
            :type="error.message ? 'is-danger' : ''"
            :message="error.message"
          >
            <b-input
              placeholder="Message"
              custom-class="custum-input"
              type="textarea"
              v-model="dataHelp.message">
            </b-input>
          </b-field>
        </form>
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              class="has-text-weight-bold"
            >
              Send
            </b-button>
          </div>
        </div>
      </div>
      <div v-else class="columns is-centered has-text-centered">
          <div class="column">
            <b-button
              type="is-primary"
              class="has-text-weight-bold"
              @click="$intercom.show()"
            >
              Open chat
            </b-button>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import CryptoJS from 'crypto-js';
import { get } from '../../api/setting';

export default {
  metaInfo: {
    title: 'Help',
  },
  components: {},
  data() {
    return {
      intercom: false,
      dataHelp: {
        title: '',
        subject: '',
        email: '',
        message: '',
      },
      error: {
        title: '',
        subject: '',
        email: '',
        message: '',
      },
      notEdit: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters([
      'user',
    ]),
  },
  methods: {},
  mounted() {
    const loadingComponent = this.$buefy.loading.open();
    get('intercom').then(response => {
      const { appId, secretKey } = response.data;
      if (appId && secretKey && this.user) {
        this.intercom = true;
        this.$intercom.boot({
          app_id: appId,
          user_id: this.user.userId,
          name: [this.user.firstName, this.user.lastName].join(' '),
          email: this.user.email,
          user_hash: CryptoJS.HmacSHA256(this.user.userId, secretKey).toString(),
          hide_default_launcher: true,
        });
        this.$intercom.show();
      }
    })
      .catch(e => {
        this.showError(e);
      }).finally(() => {
        loadingComponent.close();
      });
  },
  beforeRouteLeave(to, from, next) {
    this.$intercom.hide();
    next();
  },
};
</script>

<style>
  .intercom-messenger-frame {
      z-index: 1000 !important;
      height: 80% !important;
      width: 80% !important;
      border-radius: unset !important;
      top: 10% !important;
      bottom: 10% !important;
      left: 18% !important;
  }
  @media screen and (min-width: 768px) {
    .intercom-messenger-frame {
      width: 70% !important;
      border-radius: unset !important;
      left: 25% !important;
      right: 5%;
      max-height: 100vh !important;
    }
  }
</style>
